import * as dompack from 'dompack';
import './widgets/widgets';
import '@mod-mso/pages/events/events';
import '@mod-mso/webdesigns/mso/components/contactheader/contactheader';

import { WWBasicSite } from '@mod-ww/webdesigns/template/ww';

export class MSOSite extends WWBasicSite {
  constructor() {
    super();
  }
}

dompack.onDomReady(() => {
  const scrollerEl = document.querySelector('#go-up img');
  if (scrollerEl) {
    scrollerEl.addEventListener('click', () => window.scrollTo(0, 0));
  }

  document.addEventListener('scroll', onScroll);
  onScroll();

  AOS.init();
});

function getScrollY() {
  return window.scrollY || window.pageYOffset || document.body.scrollTop;
}

function onScroll() {
  var scrollPos = getScrollY();
  dompack.toggleClass(document.documentElement, 'scroll', scrollPos > 0);
}
